import React from "react"
import ReactDOM from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import remark from 'remark'
import remarkHTML from 'remark-html'
import Banner from "../components/banner"

const toHTML = value => remark().use(remarkHTML).processSync(value).toString()

export default function Template({ data, pageContext }) {
  const { markdownRemark } = data 
  const { frontmatter } = markdownRemark

  const bannerUrl = "/book/book-banner.jpg";

  return (
    <Layout title={frontmatter.title} description="Yoga Therapy Across the Cancer Care Continuum (Leigh Leibel and Anne Pitman) highlights the biomedical research, clinical expertise, whole-person accessible yoga practices and tender-hearted humanity offered by yoga therapists, worldwide, in cancer care.">
      <Banner title={frontmatter.title} imageUrl={bannerUrl} />

      <section className="section">
        <div className="container content">
          <h2 className="title is-2 has-text-tertiary">{frontmatter.section1Header}</h2>
          <div className="columns">
            <div className="column">
              <div className="content markdown" dangerouslySetInnerHTML={{__html: toHTML(frontmatter.text1)}} />
            </div>
            <div className="column">
              <img src="/img/book-cover.jpg" alt="Book cover" />
              <div style={{display:"flex", marginTop:"20px"}}>
                <a style={{margin:"auto"}} className="button is-secondary transition" onClick={() => {
                  const element = document.getElementById("buy-our-book");
                  element.scrollIntoView({ block: 'start',  behavior: 'smooth' });
                  
                }}>BUY NOW</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section no-top-padding">
        <div className="container content">
          <div className="columns">         
            <div className="column">
              <img src="/img/embodied-oncology-care-diagram-2022.jpg" alt="Embodied oncology care diagram" className="pink-border" />
            </div>
            <div className="column">
              <div className="content markdown" dangerouslySetInnerHTML={{__html: toHTML(frontmatter.text2)}} />
            </div>
          </div>
        </div>
      </section>
       <section className="section has-background-tertiary-faded has-text-centered">
        <div className="container content">

          <h3 id="buy-our-book" className="title is-2 has-text-tertiary">Buy our book</h3>
          <p>
            <a className="button auto is-secondary transition" href="https://us.singingdragon.com/products/yoga-therapy-across-the-cancer-care-continuum?_pos=1&_sid=a88eb0954&_ss=r">SINGING DRAGON</a><br/>
            Use code <strong>YTCCC20</strong> for a 20% discount.
          </p>

          <h4 style={{marginBottom: "8px"}} className="title is-4">Shop locally</h4>      
          <div className="buttons" style={{justifyContent: "center"}}>
            <a className="button auto is-secondary transition" href="https://thespanielstale.ca/item/giR4jZkqmxZPRN0d2MIxGQ">THE SPANIEL'S TALE</a><br/>
            <a className="button auto is-secondary transition" href="https://www.singingpebblebooks.ca/browse/filter/t/Yoga%20Therapy%20Across%20the%20Cancer%20Care%20Continuum/k/keyword">SINGING PEBBLE</a><br/>
          </div>

          <h4 style={{marginBottom: "8px"}} className="title is-4">Shop worldwide</h4>      
          <a className="button auto is-secondary transition" href="https://www.amazon.com/Yoga-Therapy-Across-Cancer-Continuum-dp-1912085917/dp/1912085917/">AMAZON</a><br/>

        </div>
      </section>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        slug
        title
        text1
        text2
      }
    }
  }
`